import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import ChipBase from '@/components/ui/Chip/Chip';

export const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 15,
  padding: theme.spacing(6, 0),
  margin: theme.spacing(5, 0),
}));

export const Headline = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(60),
  fontWeight: theme.typography.fontWeightBold,
  minHeight: 135,
  hyphens: 'auto',
}));

export const Subline = styled(Typography)(() => ({
  fontStyle: 'normal',
  color: '#666666',
  minHeight: 90,
}));

export const Chip = styled(ChipBase)(({ theme }) => ({
  alignSelf: 'flex-start',
  marginBottom: theme.spacing(2.5),
  textTransform: 'uppercase',
}));
