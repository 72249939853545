import MinusIcon from '@mui/icons-material/Remove';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Background = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})(({ theme, active }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 15,
  padding: theme.spacing(0, 2.5),
  marginBottom: theme.spacing(2.5),
  transition: 'background-color 300ms ease-in-out, padding 300ms ease-in-out',
  cursor: 'pointer',

  ...(active && {
    backgroundColor: theme.palette.grayscale[50],
    paddingBottom: theme.spacing(2.5),
    paddingTop: theme.spacing(2.5),
  }),
}));

export const IconsWrapper = styled('div')(() => ({
  position: 'relative',
  fontSize: 30,
  lineHeight: 1,
}));

export const TopMinusIcon = styled(MinusIcon, {
  shouldForwardProp: prop => prop !== 'active',
})(({ active }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  transition: 'transform 300ms ease-in',
  transform: 'rotate(90deg)',
  transformOrigin: 'center',
  ...(active && { transform: 'rotate(0deg)' }),
}));

export const Heading = styled(Typography, {
  shouldForwardProp: prop => prop !== 'active',
})(({ theme, active }) => ({
  fontWeight: theme.typography.fontWeightBold,
  position: 'relative',

  '&:after': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '5px',
    height: '1px',
    backgroundColor: theme.palette.primary.main,
    transition: 'opacity 300ms ease-in-out',
    opacity: active ? 0 : 0.4,
  },
}));
