import MinusIcon from '@mui/icons-material/Remove';
import { Box, Collapse, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import Link from '../Link/Link';
import {
  Background,
  Heading,
  IconsWrapper,
  TopMinusIcon,
} from './Accordion.style';

const Accordion = ({ onClick, active, data }) => {
  const { headline, text, link } = data;

  return (
    <Background active={active} onClick={onClick}>
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <Heading variant="h2" component="h3" color="primary" active={active}>
          {headline}
        </Heading>
        <IconsWrapper>
          <MinusIcon color="primary" fontSize="inherit" />
          <TopMinusIcon color="primary" active={active} fontSize="inherit" />
        </IconsWrapper>
      </Stack>
      <Collapse in={active}>
        {text && <Typography mt={1.5}>{text}</Typography>}
        {link && (
          <Box mt={1.5}>
            <Link link={link} />
          </Box>
        )}
      </Collapse>
    </Background>
  );
};

Accordion.propTypes = {
  data: PropTypes.shape(
    {
      headline: PropTypes.string.isRequired,
      text: PropTypes.string,
      link: PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    }.isRequired
  ),
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export default Accordion;
