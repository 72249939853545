import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Accordion from '../Accordion/Accordion';
import Link from '../Link/Link';
import Slider from '../Slider/Slider';
import { Chip, Headline, Subline, Wrapper } from './HowItWorksDesktop.style';

const HowItWorksDesktop = ({ data }) => {
  const { tag, headline, subline, description, link, steps } = data;
  const [activStepIndex, setActivStepIndex] = useState(0);

  const handleAccordionClick = index => () => {
    if (activStepIndex !== index) {
      setActivStepIndex(index);
    }
  };

  return (
    <Wrapper>
      <Grid container columnSpacing={6} rowSpacing={3.5}>
        <Grid item container columnSpacing={3.5}>
          <Grid item xs={8}>
            <Stack direction="column" pl={6}>
              {tag && <Chip size="small">{tag}</Chip>}
              <Headline variant="h1" component="h2" color="primary.dark">
                {headline}
              </Headline>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack justifyContent="flex-end" sx={{ height: '100%' }} pr={6}>
              {link && (
                <Stack justifyContent="flex-end" sx={{ height: '100%' }}>
                  <Box alignSelf="flex-end">
                    <Link link={link} />
                  </Box>
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Subline variant="h3" component="p" pl={6}>
            {subline}
          </Subline>
        </Grid>
        <Grid item xs={6}>
          {description && <Typography pr={6}>{description}</Typography>}
        </Grid>
        <Grid item xs={6}>
          <Box pl={6}>
            {steps.map((step, index) => (
              <Accordion
                key={index}
                data={step}
                active={activStepIndex === index}
                onClick={handleAccordionClick(index)}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={6} mr={-6}>
          {steps.map((step, index) =>
            index === activStepIndex ? (
              <Slider key={index} slides={step.slides} />
            ) : null
          )}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

HowItWorksDesktop.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.string,
    headline: PropTypes.string.isRequired,
    subline: PropTypes.string.isRequired,
    description: PropTypes.string,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        headline: PropTypes.string.isRequired,
        text: PropTypes.string,
        link: PropTypes.shape({
          text: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
        slides: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.shape({
              url: PropTypes.string.isRequired,
              alt: PropTypes.string,
            }).isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default HowItWorksDesktop;
