import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import Link from '../Link/Link';
import Slider from '../Slider/Slider';
import {
  Chip,
  Headline,
  InfoWrapper,
  Subline,
  Wrapper,
} from './HowItWorksMobile.style';

const HowItWorksMobile = ({ data }) => {
  const { tag, headline, subline, description, link, steps } = data;

  return (
    <Wrapper>
      <Stack direction="column" mb={6}>
        {tag && <Chip size="small">{tag}</Chip>}
        <Headline variant="h1" component="h2" color="primary.dark">
          {headline}
        </Headline>
        <Subline variant="h3" component="p" mb={description || link ? 3.5 : 0}>
          {subline}
        </Subline>
        {description && (
          <Typography mb={link ? 1.5 : 0}>{description}</Typography>
        )}
        {link && <Link link={link} />}
      </Stack>
      {steps.map(({ headline, text, link, slides }, index) => (
        <Stack key={index} mb={6.5}>
          <InfoWrapper>
            <Typography
              variant="h2"
              component="h3"
              sx={{
                fontWeight: theme => theme.typography.fontWeightBold,
              }}
              color="primary">
              {headline}
            </Typography>
            {text && <Typography mt={1.5}>{text}</Typography>}
            {link && (
              <Box mt={1.5}>
                <Link link={link} />
              </Box>
            )}
          </InfoWrapper>
          <Slider slides={slides} fullWidth />
        </Stack>
      ))}
    </Wrapper>
  );
};

HowItWorksMobile.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.string,
    headline: PropTypes.string.isRequired,
    subline: PropTypes.string.isRequired,
    description: PropTypes.string,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        headline: PropTypes.string.isRequired,
        text: PropTypes.string,
        link: PropTypes.shape({
          text: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
        slides: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.shape({
              url: PropTypes.string.isRequired,
              alt: PropTypes.string,
            }).isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default HowItWorksMobile;
