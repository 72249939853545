import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link as MuiLink, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const Link = ({ link }) => {
  return (
    <Stack alignItems="flex-start">
      <MuiLink href={link.url} color="primary">
        <Stack direction="row" alignItems="center" spacing={1} flexGrow={0}>
          <span>{link.text}</span>
          <ArrowForwardIcon />
        </Stack>
      </MuiLink>
    </Stack>
  );
};

Link.propTypes = {
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Link;
