import PropTypes from 'prop-types';
import React from 'react';

import { ChipWrapper } from './Chip.style';

const Chip = ({ children, color, size, ...props }) => {
  return (
    <ChipWrapper color={color} size={size} {...props}>
      {children}
    </ChipWrapper>
  );
};

Chip.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['blue', 'gray', 'errorLight']),
  size: PropTypes.oneOf(['medium', 'small']),
};

Chip.defaultProps = {
  color: 'blue',
  size: 'medium',
};

export default Chip;
